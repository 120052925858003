import styles from "./styles.module.scss";
import AuthButtonArrow from "@assets/icons/authButtonArrow";
import VideoImg from "@assets/img/video.png";

function Intro() {
  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <div className={styles.title}>
          The Only All-in-One Strategic Management Platform for
          <span>
            {" "}
            Enabling <br /> Scalable
          </span>{" "}
          eCommerce Growth
        </div>
        <button className={styles.button}>
          Go to your amazon account <AuthButtonArrow />
        </button>
      </div>
      <div className={styles.banner}>
        <img src={VideoImg} />
      </div>
    </div>
  );
}

export default Intro;
