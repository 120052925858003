import { TabPaneProps, Tabs as AntdTabs, TabsProps } from "antd";
import "./tabs.less";
// import SvgIcon from "@components/svg-icon/SvgIcon";

const { TabPane } = AntdTabs;

type Props = {
  tabs: (TabPaneProps & { tab: string; id: string })[];
} & TabsProps;

export const Tabs = ({ tabs, ...antdProps }: Props) => {
  return (
    <div className="tabs">
      <AntdTabs
        type="card"
        {...antdProps}
        // tabBarExtraContent={{
        //   left: <SvgIcon type="arrow-left" />,
        //   right: <SvgIcon type="arrow-right" />
        // }}
      >
        {tabs.map((tabProps) => (
          <TabPane {...tabProps} key={tabProps.id} />
        ))}
      </AntdTabs>
    </div>
  );
};
