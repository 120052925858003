import React from "react";
import ProductCard from "./ProductCard";
import styles from "./styles.module.scss";
import { performance } from "@pages/Overview/mocks/performance";

const TopPerformance: React.FC = () => {
  return (
    <div className={styles.productsList}>
      <h2>
        Compare with <br />
        <span>Top 5 Performers</span>
      </h2>
      <div className={styles.productsContainer}>
        {performance.map((product, index) => (
          <ProductCard key={index} product={product} />
        ))}
      </div>
    </div>
  );
};

export default TopPerformance;
