import React from "react";
import styles from "./styles.module.scss";

import { problems } from "@pages/Overview/mocks/problems";

const TopProblems: React.FC = () => {
  return (
    <div className={styles.container}>
      <h2>Top 5 Problems</h2>
      <div className={styles.problems}>
        {problems.map((problem, index) => (
          <div key={index} className={styles.problem}>
            <h3>{problem.title}</h3>
            <p>{problem.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopProblems;
