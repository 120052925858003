export const topPhrases = [
  {
    text: "Fridge beverage",
    rate: 7,
  },
  {
    text: "Bottles wine",
    rate: 6,
  },
  {
    text: "Level noise",
    rate: 6,
  },
  {
    text: "Looks great",
    rate: 6,
  },
  {
    text: "Works great",
    rate: 6,
  },
  {
    text: "Fridge wine",
    rate: 5,
  },
  {
    text: "Steel stainless",
    rate: 5,
  },
];
