import Axios from "axios";
import {
  IUpdateProfilePayload,
  IUpdateProfileResponse,
  IUserProfile,
} from "src/types/user.types.ts";

export class UserServices {
  public static async getProfile(): Promise<IUserProfile> {
    return (await Axios.get<IUserProfile>("/api/v1/profile")).data;
  }

  public static async updateProfile(
    body: Partial<IUpdateProfilePayload>,
  ): Promise<IUpdateProfileResponse> {
    const { data } = await Axios.patch<IUpdateProfileResponse>(
      "/api/v1/profile",
      body,
    );

    return data;
  }
}
