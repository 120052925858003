import { create } from "zustand";
import { IUserProfile } from "src/types/user.types.ts";

export interface IUserState {
  user: IUserProfile | null;
  userLoaded: boolean;
  setUser: (user: IUserProfile | null) => void;
}

export const useUserStore = create<IUserState>((set) => ({
  user: null,
  userLoaded: false,
  setUser: (user: IUserProfile | null) => {
    return set((): Partial<IUserState> => {
      return { user, userLoaded: !!user };
    });
  },
}));
