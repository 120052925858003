import { IWidget } from "@pages/Dashboard/types/dashboard.types.ts";
import { widgetMocks } from "@pages/Dashboard/mocks/widget.mocks.ts";

export class DashboardAPI {
  static async getWidgets(): Promise<IWidget[]> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(widgetMocks);
      }, 300);
    });
  }
}
