import "./index.css";
import { Rating } from "@pages/Dashboard/features/Products/Rating";
import Fridge from "@assets/img/fridge.png";
import ProductCode from "@components/ProductCode";
import DataBlock from "@components/DataBlock";
import { mockDataBlock } from "./mock";

export const Description = () => {
  return (
    <div className="product-description">
      <picture className="product-description__picture">
        <img src={Fridge} alt="Fridge" />
      </picture>
      <div className="product-description__description">
        <h2 className="product-description__title heading-2">
          Zephyr Presrv 24 Inch Wine Fridge & Beverage Refrigerator Dual Zone
          Under Counter - Wine Cooler Cellars Small Beer Fridge Cabinet Drink
          Chiller Freestanding with French Glass Door 21 Bottles, 64 Cans
        </h2>
        <Rating rating={4.5} />
        <ProductCode code="B07GWVP5RC" />
        <DataBlock data={mockDataBlock} />
      </div>
    </div>
  );
};
