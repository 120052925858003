export const performance = [
  {
    title:
      "Antarctic Star 24 Bottle Wine Cooler/Cabinet Beverage Refrigerator Small Mini Wine Cellar...",
    rating: 3.6,
    code: "B07S39J8JX",
  },
  {
    title: "Ivation Compressor Wine Cooler",
    rating: 3.5,
    code: "B08G4S2FPR",
  },
  {
    title:
      "24 Bottle Compressor Wine Cooler Refrigerator, Small Freestanding Wine Fridge for Red, White and Ch...",
    rating: 4.3,
    code: "B08N6TPHRH",
  },
  {
    title:
      "Ivation 18 Bottle Compressor Wine Cooler Refrigerator w/Lock, Large Freestanding Wine Cellar For Red,...",
    rating: 3.6,
    code: "B08G4P1L3Y",
  },
  {
    title:
      "Schmécké 12 Bottle Compressor Wine Cooler Refrigerator w/Lock - Large Freestanding Wine Cellar Fo...",
    rating: 3.2,
    code: "B085LYB69D",
  },
];
