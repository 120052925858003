export const problems = [
  {
    title: "Poor Customer service",
    description:
      "Many reviewers expressed dissatisfaction with the lack of responsiveness and unhelpful nature of the customer service team when they encountered issues with the product or delivery.",
  },
  {
    title: "Excessive shipping time",
    description:
      "Several users complained that the product took an unusually long time to be delivered after placing an order.",
  },
  {
    title: "Defective product",
    description:
      "I have been using this product for a few weeks now and I can already see significant improvements.",
  },
  {
    title: "Inaccurate product description",
    description:
      "Some customers felt misled by inaccurate or incomplete product descriptions, leading to disappointment upon receiving the item.",
  },
  {
    title: "Difficulty in returning",
    description:
      "Several users faced challenges in returning the product due to complicated return processes, lack of clear instructions, or uncooperative customer service.",
  },
];
