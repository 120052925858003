import React, { useState } from "react";
import styles from "./styles.module.scss";
import { reviews } from "@pages/Overview/mocks/reviews";
import LoadMoreButton from "@components/LoadMoreButton";

interface Review {
  rating: number;
  title: string;
  content: string;
}

const Reviews: React.FC = () => {
  const [visibleReviews, setVisibleReviews] = useState<number>(3);

  const loadMoreReviews = () => {
    setVisibleReviews((prevVisibleReviews) => prevVisibleReviews + 3);
  };

  return (
    <div className={styles.container}>
      <div className={styles.reviewsList}>
        <h2>Reviews</h2>
        {reviews
          .slice(0, visibleReviews)
          .map((review: Review, index: number) => (
            <div key={index} className={styles.review}>
              <div className={styles.rating}>
                <span>⭐ {review.rating}</span>
                <div>
                  <h3>TITLE:</h3>
                  <p>{review.title}</p>
                </div>
              </div>
              <div className={styles.details}>
                <p>{review.content}</p>
              </div>
            </div>
          ))}
      </div>
      {visibleReviews < reviews.length && (
        <LoadMoreButton onClick={loadMoreReviews} />
      )}
    </div>
  );
};

export default Reviews;
