export default () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.02243 6.224C8.90977 6.33666 8.75696 6.39995 8.59763 6.39995C8.4383 6.39995 8.2855 6.33666 8.17283 6.224C8.06017 6.11133 7.99688 5.95853 7.99688 5.7992C7.99688 5.63986 8.06017 5.48706 8.17283 5.3744L12.9728 0.574396C13.0286 0.51861 13.0948 0.474358 13.1677 0.444168C13.2406 0.413977 13.3187 0.398438 13.3976 0.398438C13.4765 0.398438 13.5546 0.413977 13.6275 0.444168C13.7004 0.474358 13.7666 0.51861 13.8224 0.574396C13.8782 0.630181 13.9225 0.696408 13.9527 0.769295C13.9829 0.842183 13.9984 0.920303 13.9984 0.999196C13.9984 1.07809 13.9829 1.15621 13.9527 1.2291C13.9225 1.30198 13.8782 1.36821 13.8224 1.424L9.02243 6.224ZM1.82243 13.424C1.76665 13.4798 1.70042 13.524 1.62753 13.5542C1.55465 13.5844 1.47653 13.6 1.39763 13.6C1.31874 13.6 1.24062 13.5844 1.16773 13.5542C1.09485 13.524 1.02862 13.4798 0.972833 13.424C0.917048 13.3682 0.872796 13.302 0.842605 13.2291C0.812414 13.1562 0.796875 13.0781 0.796875 12.9992C0.796875 12.9203 0.812414 12.8422 0.842605 12.7693C0.872796 12.6964 0.917048 12.6302 0.972833 12.5744L5.77283 7.7744C5.8855 7.66173 6.0383 7.59844 6.19763 7.59844C6.35696 7.59844 6.50977 7.66173 6.62243 7.7744C6.7351 7.88706 6.79839 8.03987 6.79839 8.1992C6.79839 8.35853 6.7351 8.51133 6.62243 8.624L1.82243 13.424Z"
        fill="white"
      />
      <path
        d="M1.39688 13.5984C1.23775 13.5984 1.08513 13.5352 0.972611 13.4227C0.860089 13.3102 0.796875 13.1576 0.796875 12.9984C0.796875 12.8393 0.860089 12.6867 0.972611 12.5742C1.08513 12.4617 1.23775 12.3984 1.39688 12.3984H6.19688C6.35601 12.3984 6.50862 12.4617 6.62114 12.5742C6.73366 12.6867 6.79688 12.8393 6.79688 12.9984C6.79688 13.1576 6.73366 13.3102 6.62114 13.4227C6.50862 13.5352 6.35601 13.5984 6.19688 13.5984H1.39688Z"
        fill="white"
      />
      <path
        d="M1.99687 12.9984C1.99687 13.1576 1.93366 13.3102 1.82114 13.4227C1.70862 13.5352 1.556 13.5984 1.39687 13.5984C1.23775 13.5984 1.08513 13.5352 0.972611 13.4227C0.860089 13.3102 0.796875 13.1576 0.796875 12.9984V8.19844C0.796875 8.03931 0.860089 7.88669 0.972611 7.77417C1.08513 7.66165 1.23775 7.59844 1.39687 7.59844C1.556 7.59844 1.70862 7.66165 1.82114 7.77417C1.93366 7.88669 1.99687 8.03931 1.99687 8.19844V12.9984ZM13.9969 5.79844C13.9969 5.95757 13.9337 6.11018 13.8211 6.2227C13.7086 6.33522 13.556 6.39844 13.3969 6.39844C13.2377 6.39844 13.0851 6.33522 12.9726 6.2227C12.8601 6.11018 12.7969 5.95757 12.7969 5.79844V0.998437C12.7969 0.839308 12.8601 0.686695 12.9726 0.574174C13.0851 0.461652 13.2377 0.398438 13.3969 0.398438C13.556 0.398438 13.7086 0.461652 13.8211 0.574174C13.9337 0.686695 13.9969 0.839308 13.9969 0.998437V5.79844Z"
        fill="white"
      />
      <path
        d="M8.6 1.59844C8.44087 1.59844 8.28826 1.53522 8.17574 1.4227C8.06321 1.31018 8 1.15757 8 0.998438C8 0.839308 8.06321 0.686695 8.17574 0.574174C8.28826 0.461652 8.44087 0.398438 8.6 0.398438H13.4C13.5591 0.398438 13.7117 0.461652 13.8243 0.574174C13.9368 0.686695 14 0.839308 14 0.998438C14 1.15757 13.9368 1.31018 13.8243 1.4227C13.7117 1.53522 13.5591 1.59844 13.4 1.59844H8.6Z"
        fill="white"
      />
    </svg>
  );
};
