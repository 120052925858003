export const reviews = [
  {
    rating: 5,
    title: "Great product",
    content:
      "This is really nice but should be for the price. Quiet enough with a good amount of storage. The shelves slide out correctly and has a nice sleek look. Also the shipping was white glove and very efficient with on time delivery. They call to set up appointment and give you time frame which is adhered to. The delivery men were super. Brought in, unpacked and set everything up. Extremely helpful to get that service. Would highly recommend the product and company.",
  },
  {
    rating: 5,
    title: "Quiet, beautiful and excellent quality",
    content:
      'We are so happy with our Zephyr Presrv 24" Wine Fridge & Beverage Refrigerator. It is very beautiful with a super practical dual zone where we can put bottles and cans of beverages. We love it and have plans to put it under our to be added wet bar counter. John at Right Stuff Products has provided me with excellent and quick service support as well. I highly recommend this seller for this product.',
  },
  {
    rating: 1,
    title: "Arrived with doors completely uneven",
    content:
      "Horrible quality. I bought a $2k fridge expecting it to look good and function properly. The doors arrived completely uneven — one both higher and set back further than the other — with no way to adjust them.",
  },
  {
    rating: 5,
    title: "Great product",
    content:
      "This is really nice but should be for the price. Quiet enough with a good amount of storage. The shelves slide out correctly and has a nice sleek look. Also the shipping was white glove and very efficient with on time delivery. They call to set up appointment and give you time frame which is adhered to. The delivery men were super. Brought in, unpacked and set everything up. Extremely helpful to get that service. Would highly recommend the product and company.",
  },
  {
    rating: 5,
    title: "Quiet, beautiful and excellent quality",
    content:
      'We are so happy with our Zephyr Presrv 24" Wine Fridge & Beverage Refrigerator. It is very beautiful with a super practical dual zone where we can put bottles and cans of beverages. We love it and have plans to put it under our to be added wet bar counter. John at Right Stuff Products has provided me with excellent and quick service support as well. I highly recommend this seller for this product.',
  },
  {
    rating: 1,
    title: "Arrived with doors completely uneven",
    content:
      "Horrible quality. I bought a $2k fridge expecting it to look good and function properly. The doors arrived completely uneven — one both higher and set back further than the other — with no way to adjust them.",
  },
  {
    rating: 5,
    title: "Great product",
    content:
      "This is really nice but should be for the price. Quiet enough with a good amount of storage. The shelves slide out correctly and has a nice sleek look. Also the shipping was white glove and very efficient with on time delivery. They call to set up appointment and give you time frame which is adhered to. The delivery men were super. Brought in, unpacked and set everything up. Extremely helpful to get that service. Would highly recommend the product and company.",
  },
  {
    rating: 5,
    title: "Quiet, beautiful and excellent quality",
    content:
      'We are so happy with our Zephyr Presrv 24" Wine Fridge & Beverage Refrigerator. It is very beautiful with a super practical dual zone where we can put bottles and cans of beverages. We love it and have plans to put it under our to be added wet bar counter. John at Right Stuff Products has provided me with excellent and quick service support as well. I highly recommend this seller for this product.',
  },
  {
    rating: 1,
    title: "Arrived with doors completely uneven",
    content:
      "Horrible quality. I bought a $2k fridge expecting it to look good and function properly. The doors arrived completely uneven — one both higher and set back further than the other — with no way to adjust them.",
  },
];
