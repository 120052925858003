import { SVGProps } from "react";

export default (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3945_1103)">
        <path
          d="M14.7598 2V3.40278M14.7598 25.8472V27.25M3.53754 14.625H2.13477M6.78374 6.64897L5.64171 5.50694M22.7358 6.64897L23.8778 5.50694M6.78374 22.6068L5.64171 23.7432M22.7358 22.6068L23.8778 23.7432M27.3848 14.625H25.982M20.3709 14.625C20.3709 17.7239 17.8586 20.2361 14.7598 20.2361C11.6608 20.2361 9.14865 17.7239 9.14865 14.625C9.14865 11.5261 11.6608 9.01389 14.7598 9.01389C17.8586 9.01389 20.3709 11.5261 20.3709 14.625Z"
          stroke="currentColor"
          strokeWidth="2.80556"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3945_1103">
          <rect
            width="30"
            height="30"
            fill="white"
            transform="translate(0.134766)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
