import React, { ReactNode } from "react";
import styles from "./PageWrapper.module.scss";

interface PageWrapperProps {
  children: ReactNode;
}

const PageWrapper: React.FC<PageWrapperProps> = ({ children }) => {
  return (
    <div className={styles.pageWrapper}>
      <div className={styles.pageWrapperInner}>{children}</div>
    </div>
  );
};

export default PageWrapper;
