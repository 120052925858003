import { Checkbox, CheckboxProps } from "antd";
import SvgIcon from "@components/svg-icon/SvgIcon";

import "./user-checkbox.less";
import { UserAvatar } from "@components/UserAvatar";

export type UserCheckboxProps = {
  username: string;
  avatarSrc?: string;
  onRemoveClick?: () => void;
} & Omit<CheckboxProps, "className">;

export const UserCheckbox = ({
  username,
  avatarSrc,
  onRemoveClick,
  ...checkboxProps
}: UserCheckboxProps) => {
  return (
    <Checkbox className="user-checkbox" {...checkboxProps}>
      <UserAvatar
        username={username}
        avatarSrc={avatarSrc}
        className="user-checkbox__avatar"
      />
      <span className="user-checkbox__close" onClick={onRemoveClick}>
        <SvgIcon type="close" />
      </span>
    </Checkbox>
  );
};
