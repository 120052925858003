import React from "react";
import "./index.css";
import { Description } from "../Description";
import ProblemsBlock from "@components/ProblemsBlock";
import { Products } from "@pages/Dashboard/mocks/products";

export const Product: React.FC = () => {
  const product = Products[0];

  return (
    <div className="product">
      <Description />
      <div className="product-widgets">
        <div className="problems-block">
          <ProblemsBlock topProblems={product.topProblems} />
        </div>
      </div>
    </div>
  );
};
