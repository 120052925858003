import React, { useState } from "react";
import styles from "./styles.module.scss";
import { IProductTopPhrase } from "@pages/Dashboard/types/dashboard.types";
import { topPhrases } from "@pages/Dashboard/mocks/topPhrases";
import Arrow from "@assets/icons/leftArr.svg";

const ITEMS_PER_PAGE = 7;

const Phases: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(topPhrases.length / ITEMS_PER_PAGE);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const selectedPhrases = topPhrases.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE,
  );

  const topPhrasesJSX = selectedPhrases.map((p: IProductTopPhrase) => {
    const width = `${(p.rate / 7) * 100}%`;
    return (
      <div key={p.text} className={styles.productPhrase}>
        <div className={styles.productPhraseHeader}>
          <span className={`${styles.productPhraseText} bodySmall`}>
            {p.text}
          </span>
          <span className={`${styles.productPhraseRate} bodySmall`}>
            {p.rate}
          </span>
        </div>
        <div className={styles.productPhraseProgress} style={{ width }} />
      </div>
    );
  });

  return (
    <div className={styles.phases}>
      <div className={styles.productTopPhrases}>
        <header className={styles.productTopPhrasesHeader}>
          <h3 className={`${styles.productTopPhrasesTitle} heading3`}>
            Top Phrases
          </h3>
        </header>
        <div className={styles.productTopPhrasesList}>{topPhrasesJSX}</div>
        <div className={styles.pagination}>
          <button
            className={styles.pageButton}
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            <img src={Arrow} height={14} width={9} />
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              className={`${styles.pageButton} ${currentPage === index + 1 ? styles.active : ""}`}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </button>
          ))}
          <button
            className={styles.pageButton}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <img src={Arrow} height={14} width={9} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Phases;
