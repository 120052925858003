import { ChangeEvent, useState } from "react";
import { Divider } from "antd";

import { Image } from "@entities/Image";
import { isDevelopMode } from "@lib/env";
import SvgIcon from "@components/svg-icon/SvgIcon";

import "./image-uploader.less";
import {
  useDeleteImage,
  useExtractTags,
  useUploadFile,
} from "./hooks/useImageServices";

type ImageUploaderProps = {
  disabled?: boolean;
  onTagsExtracted: (tags: string[]) => void;
};

const PLACEHOLDER_COUNT = 6;

export const ImageUploader = ({
  onTagsExtracted,
  disabled,
}: ImageUploaderProps) => {
  const [images, setImages] = useState<Image[]>([]);
  const { mutate: uploadFile, isLoading: isUploading } = useUploadFile({
    onSuccess: ({ data }) => {
      setImages((prev) => [...prev, data]);
      extractTags({ id: data.id });
    },
  });
  const { mutate: deleteFile, isLoading: isDeleting } = useDeleteImage({
    onSuccess: (_, { id }) => {
      setImages((prev) => prev.filter((image) => image.id !== id));
    },
  });
  const { mutate: extractTags, isLoading: isTagsExtracting } = useExtractTags({
    onSuccess: ({ data }) => {
      onTagsExtracted(data.features);
    },
  });

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      uploadFile({ img: file });
    };
    reader.readAsDataURL(file);
  };

  const handleRemove = (id: Image["id"]) => {
    deleteFile({ id });
  };

  const isLoading = isUploading || isDeleting || isTagsExtracting;
  const placeholders = PLACEHOLDER_COUNT - images.length;

  return (
    <div className="image-uploader">
      <Divider />
      <h4 className="image-uploader__title">Extract tags from images *</h4>
      <div className="image-uploader__list">
        {!isLoading && (
          <>
            {images.map(({ url, id }) => (
              <div className="image-uploader__placeholder" key={url}>
                <img
                  src={`${isDevelopMode ? "https://dev.nyle.ai" : ""}${url}`}
                  alt="uploaded img"
                  className="image-uploader__img"
                />
                <div
                  className="delete-image"
                  onClick={handleRemove.bind(null, id)}
                >
                  <SvgIcon type="close" />
                </div>
              </div>
            ))}
            {new Array(placeholders).fill(null).map((_, i) => (
              <div key={i} className="image-uploader__placeholder">
                {i === 0 && (
                  <label>
                    <SvgIcon className="empty-icon" type="plus-camera" />
                    <input
                      type="file"
                      accept="image/png, image/jpeg"
                      disabled={disabled}
                      onChange={handleUpload}
                    />
                  </label>
                )}
              </div>
            ))}
          </>
        )}
        {isLoading && (
          <div className="image-uploader__loading-container">
            <SvgIcon type="loading" />
          </div>
        )}
      </div>
      <p className="image-uploader__field-hint">
        * No more than 6 images with max size of 200KB each (png, jpg)
      </p>
    </div>
  );
};
