import React from "react";
import "./index.css";
import { Metric } from "@pages/Dashboard/mocks/mockMetricsData";

interface LegendProps {
  handleOpenModal: () => void;
  selectedMetrics: Metric[];
}

const COLORS = [
  "#80C67A",
  "#1BB08D",
  "#FFFFFF",
  "#6B4DBA",
  "#C8A857",
  "#5295E0",
];

export const Legend: React.FC<LegendProps> = ({
  handleOpenModal,
  selectedMetrics,
}) => {
  return (
    <div className="sales-performance__legend">
      <div className="sales-performance__legendGroup">
        {selectedMetrics.map((metric, index) => (
          <div key={metric.key} className="sales-performance__legendItem">
            <div
              className="sales-performance__legendCircle"
              style={{ backgroundColor: COLORS[index % COLORS.length] }}
            />
            <div className="sales-performance__legendLabel body-large bold">
              {metric.name}
            </div>
          </div>
        ))}
      </div>
      <button className="sales-performance__button" onClick={handleOpenModal}>
        Metrics
      </button>
    </div>
  );
};
