export default () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.62419 18.4232C6.56841 18.479 6.50218 18.5233 6.42929 18.5534C6.3564 18.5836 6.27828 18.5992 6.19939 18.5992C6.1205 18.5992 6.04238 18.5836 5.96949 18.5534C5.8966 18.5233 5.83038 18.479 5.77459 18.4232C5.71881 18.3674 5.67455 18.3012 5.64436 18.2283C5.61417 18.1554 5.59863 18.0773 5.59863 17.9984C5.59863 17.9195 5.61417 17.8414 5.64436 17.7685C5.67455 17.6956 5.71881 17.6294 5.77459 17.5736L10.5746 12.7736C10.6304 12.7178 10.6966 12.6736 10.7695 12.6434C10.8424 12.6132 10.9205 12.5977 10.9994 12.5977C11.0783 12.5977 11.1564 12.6132 11.2293 12.6434C11.3022 12.6736 11.3684 12.7178 11.4242 12.7736C11.48 12.8294 11.5242 12.8956 11.5544 12.9685C11.5846 13.0414 11.6001 13.1195 11.6001 13.1984C11.6001 13.2773 11.5846 13.3554 11.5544 13.4283C11.5242 13.5012 11.48 13.5674 11.4242 13.6232L6.62419 18.4232Z"
        fill="white"
      />
      <path
        d="M11.5955 17.9996C11.5955 18.1587 11.5323 18.3114 11.4198 18.4239C11.3073 18.5364 11.1546 18.5996 10.9955 18.5996C10.8364 18.5996 10.6838 18.5364 10.5712 18.4239C10.4587 18.3114 10.3955 18.1587 10.3955 17.9996V13.1996C10.3955 13.0405 10.4587 12.8879 10.5712 12.7753C10.6838 12.6628 10.8364 12.5996 10.9955 12.5996C11.1546 12.5996 11.3073 12.6628 11.4198 12.7753C11.5323 12.8879 11.5955 13.0405 11.5955 13.1996V17.9996Z"
        fill="white"
      />
      <path
        d="M6.19863 13.7992C6.0395 13.7992 5.88689 13.736 5.77437 13.6235C5.66185 13.5109 5.59863 13.3583 5.59863 13.1992C5.59863 13.0401 5.66185 12.8875 5.77437 12.7749C5.88689 12.6624 6.0395 12.5992 6.19863 12.5992H10.9986C11.1578 12.5992 11.3104 12.6624 11.4229 12.7749C11.5354 12.8875 11.5986 13.0401 11.5986 13.1992C11.5986 13.3583 11.5354 13.5109 11.4229 13.6235C11.3104 13.736 11.1578 13.7992 10.9986 13.7992H6.19863ZM13.8234 11.224C13.7108 11.3367 13.558 11.4 13.3986 11.4C13.2393 11.4 13.0865 11.3367 12.9738 11.224C12.8612 11.1113 12.7979 10.9585 12.7979 10.7992C12.7979 10.6399 12.8612 10.4871 12.9738 10.3744L17.7738 5.5744C17.8296 5.51861 17.8958 5.47436 17.9687 5.44417C18.0416 5.41398 18.1197 5.39844 18.1986 5.39844C18.2775 5.39844 18.3556 5.41398 18.4285 5.44417C18.5014 5.47436 18.5676 5.51861 18.6234 5.5744C18.6792 5.63018 18.7235 5.69641 18.7537 5.7693C18.7839 5.84218 18.7994 5.9203 18.7994 5.9992C18.7994 6.07809 18.7839 6.15621 18.7537 6.2291C18.7235 6.30198 18.6792 6.36821 18.6234 6.424L13.8234 11.224Z"
        fill="white"
      />
      <path
        d="M13.4018 11.3992C13.2426 11.3992 13.09 11.336 12.9775 11.2235C12.865 11.111 12.8018 10.9583 12.8018 10.7992C12.8018 10.6401 12.865 10.4875 12.9775 10.375C13.09 10.2624 13.2426 10.1992 13.4018 10.1992H18.2018C18.3609 10.1992 18.5135 10.2624 18.626 10.375C18.7385 10.4875 18.8018 10.6401 18.8018 10.7992C18.8018 10.9583 18.7385 11.111 18.626 11.2235C18.5135 11.336 18.3609 11.3992 18.2018 11.3992H13.4018Z"
        fill="white"
      />
      <path
        d="M14.0018 10.7984C14.0018 10.9576 13.9385 11.1102 13.826 11.2227C13.7135 11.3352 13.5609 11.3984 13.4018 11.3984C13.2426 11.3984 13.09 11.3352 12.9775 11.2227C12.865 11.1102 12.8018 10.9576 12.8018 10.7984V5.99844C12.8018 5.83931 12.865 5.6867 12.9775 5.57417C13.09 5.46165 13.2426 5.39844 13.4018 5.39844C13.5609 5.39844 13.7135 5.46165 13.826 5.57417C13.9385 5.6867 14.0018 5.83931 14.0018 5.99844V10.7984Z"
        fill="white"
      />
    </svg>
  );
};
