import { useCallback, useRef } from "react";
import { useDropzone } from "react-dropzone";
import cn from "classnames";
import { Button, Popover, Radio, Slider, Spin, Switch } from "antd";

import SvgIcon from "@components/svg-icon/SvgIcon";

type ImageEnhanceToolbarProps = {
  file?: File;
  isLoading?: boolean;
  onStart: () => void;
  onDrop: (data: File) => void;
  onClear: () => void;
  sizeMode: "enlarge" | "custom";
  setSizeMode: (data: "enlarge" | "custom") => void;
  enlargeSlider: number;
  setEnlargeSlider: (data: number) => void;
};

export const ImageEnhanceToolbar = ({
  file,
  isLoading,
  onStart,
  onDrop,
  onClear,
  sizeMode,
  setSizeMode,
  enlargeSlider,
  setEnlargeSlider,
}: ImageEnhanceToolbarProps) => {
  const sliderWrapperRef = useRef<HTMLDivElement>(null);

  const handleStartEnchancing = useCallback(async () => {
    onStart?.();
  }, [onStart]);

  const _onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onDrop(acceptedFiles[0]);
    },
    [onDrop],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: _onDrop,
    accept: "image/png, image/jpeg",
    maxFiles: 1,
    multiple: false,
  });

  return (
    <div className="image-enhance-toolbar">
      {file && (
        <div className="image-enhance-toolbar__image">
          <div className="image-enhance-toolbar__image-close" onClick={onClear}>
            <SvgIcon type="close" />
          </div>

          <div className="image-enhance-toolbar__image-content">
            {isLoading && (
              <div className="image-enhance-toolbar__image-loader">
                <Spin size="large" />
              </div>
            )}

            {!isLoading && (
              <>
                <img src={URL.createObjectURL(file)} alt="uploaded file" />
                <span className="image-enhance-toolbar__image-caption">
                  {file.name}
                </span>
              </>
            )}
          </div>
        </div>
      )}
      {!file && (
        <div
          className={cn("image-enhance-toolbar__dropdown", {
            "image-enhance-toolbar__dropdown_isDragActive": isDragActive,
          })}
          {...getRootProps()}
        >
          <div className="image-enhance-toolbar__dropdown-icon">
            <SvgIcon type="upload" />
          </div>
          <div className="image-enhance-toolbar__dropdown-hint">
            Drop your file here or click to upload
            <span>JPEG, PNG up to 50MB</span>
            <input {...getInputProps()} />
          </div>
        </div>
      )}

      <div className="image-enhance-toolbar__repair">
        <span>Repair only</span>
        <Popover
          content={
            <div>
              Remove noise, Jpeg artifacts and sharpening, while keeping the
              original size
            </div>
          }
        >
          <div className="image-enhance-toolbar__repair-question">?</div>
        </Popover>
        <Switch />
      </div>

      <div className="image-enhance-toolbar__buttons">
        <Radio.Group
          value={sizeMode}
          onChange={(e) => {
            setSizeMode(e.target.value);
          }}
        >
          <Radio.Button value="enlarge">Enlarge by</Radio.Button>
          <Radio.Button value="custom">Custom size</Radio.Button>
        </Radio.Group>
      </div>
      <div className="image-enhance-toolbar__slider" ref={sliderWrapperRef}>
        <Slider
          min={1}
          max={4}
          step={1}
          tooltipVisible={true}
          tooltipPlacement={"bottom"}
          getTooltipPopupContainer={() => sliderWrapperRef.current}
          tipFormatter={(val) => <span>{val}x</span>}
          value={enlargeSlider}
          onChange={(val) => {
            setEnlargeSlider(val);
          }}
        />
      </div>
      <div className="image-enhance-toolbar__cta">
        <Button
          block
          shape="round"
          disabled={!file || isLoading}
          onClick={handleStartEnchancing}
        >
          <SvgIcon type="play" />
          Start
        </Button>
      </div>
    </div>
  );
};
